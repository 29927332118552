import Vue from "vue";
import VueRouter from "vue-router";

// Routes
import { canNavigate } from "@/libs/acl/routeProtection";
import {
  isUserLoggedIn,
  getUserData,
  getHomeRouteForLoggedInUser,
} from "@/auth/utils";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: "/",
      name: "dashboard",
      component: () => import("@/views/dashboard/Dashboard.vue"),
      meta: {
        authenticate: true,
      },
    },
    {
      path: "/login",
      name: "auth-login",
      component: () => import("@/views/authentication/Login.vue"),
      meta: {
        layout: "full",
        resource: "Auth",
        redirectIfLoggedIn: true,
      },
    },
    {
      path: "/register",
      name: "auth-register",
      component: () => import("@/views/authentication/Register.vue"),
      meta: {
        layout: "full",
        resource: "Auth",
        redirectIfLoggedIn: true,
      },
    },
    {
      path: "/miscellaneous/not-authorized",
      name: "misc-not-authorized",
      component: () => import("@/views/miscellaneous/NotAuthorized.vue"),
      meta: {
        layout: "full",
        resource: "Auth",
      },
    },
    {
      path: "/forgot-access-code",
      name: "auth-forgot-access-code",
      component: () => import("@/views/authentication/ForgotAccessCode.vue"),
      meta: {
        layout: "full",
        resource: "Auth",
        redirectIfLoggedIn: true,
      },
    },
    {
      path: "/calendar",
      name: "calendar",
      component: () => import("@/views/calendar/Calendar.vue"),
      meta: {
        pageTitle: "Collection Calendar",
        breadcrumb: [
          {
            text: "Collection Calendar",
            active: true,
          },
        ],
        authenticate: true,
      },
    },
    {
      path: "/documents",
      name: "documents",
      component: () => import("@/views/documents/Documents.vue"),
      meta: {
        pageTitle: "Documents",
        breadcrumb: [
          {
            text: "Documents",
            active: true,
          },
        ],
        authenticate: true,
      },
    },
    {
      path: "/notifications",
      name: "notifications",
      component: () => import("@/views/notifications/Notifications.vue"),
      meta: {
        pageTitle: "Notifications",
        breadcrumb: [
          {
            text: "Notifications",
            active: true,
          },
        ],
        authenticate: true,
      },
    },
    {
      path: "/invoices",
      name: "invoices",
      component: () => import("@/views/invoices/Invoices.vue"),
      meta: {
        pageTitle: "Invoices",
        breadcrumb: [
          {
            text: "Invoices",
            active: true,
          },
        ],
        authenticate: true,
      },
    },
    {
      path: "/marketplace",
      name: "marketplace",
      component: () => import("@/views/marketplace/Marketplace.vue"),
      meta: {
        pageTitle: "Marketplace",
        breadcrumb: [
          {
            text: "Partner Marketplace",
            active: true,
          },
        ],
        authenticate: true,
      },
    },
    {
      path: "/news/news-waste",
      name: "news-waste",
      component: () => import("@/views/news/news-waste/BlogList.vue"),
      meta: {
        breadcrumb: [
          {
            text: "Waste News",
            active: true,
          },
        ],
        authenticate: true,
      },
    },
    {
      path: "/news/news-waste/:id",
      name: "news-waste-detail",
      component: () => import("@/views/news/news-waste/BlogDetail.vue"),
      meta: {
        pageTitle: "Blog Detail",
        breadcrumb: [
          {
            text: "News",
            active: true,
          },
          {
            text: "Blog",
            active: true,
          },
        ],
        authenticate: true,
      },
    },
    {
      path: "/news/Knowledge-base",
      name: "news-knowledgebase",
      component: () => import("@/views/news/faq/Faq.vue"),
      meta: {
        breadcrumb: [
          {
            text: "Knowledge Base",
            active: true,
          },
        ],
        authenticate: true,
      },
    },
    {
      path: "/contact",
      name: "contact",
      component: () =>
        import("@/views/contact/contact-feedback/ContactFeedback.vue"),
      meta: {
        breadcrumb: [
          {
            text: "Contact",
            active: true,
          },
        ],
        authenticate: true,
      },
      props: true,
    },
    {
      path: "/print-friendly/calendar-list",
      name: "cal-list",
      target: "_blank",
      component: () => import("@/views/print-versions/CalendarList.vue"),
      meta: {
        authenticate: true,
        layout: "full",
      },
    },
    {
      path: "*",
      component: () => import("@/views/error/Error404.vue"),
    },
  ],
});

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn();
  if (to.meta.authenticate && !isLoggedIn) {
    next("/login");
  } else if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    next("/");
  } else {
    next();
  }
});

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});

export default router;
