import { $themeBreakpoints } from "@themeConfig";

export default {
    namespaced: true,
    state: {
        windowWidth: 0,
        shallShowOverlay: false,
        selectedPremiseName: localStorage.getItem("selectedPremiseName") || "",
        selectedPremiseID: localStorage.getItem("selectedPremiseID") || "",
        selectedPremiseHumanID: localStorage.getItem("selectedPremiseHumanID") || "",
        selectedPremiseAddress: localStorage.getItem("selectedPremiseAddress") || "",
        selectedPremiseLastLogin: localStorage.getItem("selectedPremiseLAstLogin") || "",
        selectedPremiseProductList: JSON.parse(localStorage.getItem("selectedPremiseProductList")) || [],
        selectedPremiseNextCollections: JSON.parse(localStorage.getItem("selectedPremiseNextCollections")) || [],
        selectedPremiseCollections: JSON.parse(localStorage.getItem("selectedPremiseCollections")) || [],
        selectedPremiseNotifications: JSON.parse(localStorage.getItem("selectedPremiseNotifications")) || [],
        selectedPremiseNewNotifications: JSON.parse(localStorage.getItem("selectedPremiseNotifications")) || [],
        selectedPremiseDocuments: JSON.parse(localStorage.getItem("selectedPremiseDocuments")) || [],
        selectedUserLevels: JSON.parse(localStorage.getItem("selectedUserLevels")) || "",
        selectedPremiseHasInvoices: JSON.parse(localStorage.getItem("selectedPremiseHasInvoices")) || "",
    },
    getters: {
        currentBreakPoint: (state) => {
            const { windowWidth } = state;
            if (windowWidth >= $themeBreakpoints.xl) return "xl";
            if (windowWidth >= $themeBreakpoints.lg) return "lg";
            if (windowWidth >= $themeBreakpoints.md) return "md";
            if (windowWidth >= $themeBreakpoints.sm) return "sm";
            return "xs";
        },
        events: (state) => state.teststore,
    },
    mutations: {
        UPDATE_WINDOW_WIDTH(state, val) {
            state.windowWidth = val;
        },
        UPDATE_SELECTED_PREMISE_NAME(state, val) {
            state.selectedPremiseName = val;
        },
        UPDATE_SELECTED_PREMISE_ID(state, val) {
            state.selectedPremiseID = val;
        },
        UPDATE_SELECTED_PREMISE_HUMAN_ID(state, val) {
            state.selectedPremiseHumanID = val;
        },
        UPDATE_SELECTED_PREMISE_ADDRESS(state, val) {
            state.selectedPremiseAddress = val;
        },
        UPDATE_SELECTED_PREMISE_PRODUCT_LIST(state, val) {
            state.selectedPremiseProductList = val;
        },
        UPDATE_SELECTED_PREMISE_NEXT_COLLECTIONS(state, val) {
            state.selectedPremiseNextCollections = val;
        },
        UPDATE_SELECTED_PREMISE_COLLECTIONS(state, val) {
            state.selectedPremiseCollections = val;
        },
        UPDATE_SELECTED_PREMISE_NOTIFICATIONS(state, val) {
            state.selectedPremiseNotifications = val;
        },
        UPDATE_SELECTED_PREMISE_NEW_NOTIFICATIONS(state, val) {
            state.selectedPremiseNewNotifications = val;
        },
        UPDATE_SELECTED_PREMISE_DOCUMENTS(state, val) {
            state.selectedPremiseDocuments = val;
        },
        UPDATE_SELECTED_PREMISE_LAST_LOGIN(state, val) {
            state.selectedPremiseLastLogin = val;
        },
        UPDATE_SELECTED_USER_LEVELS(state, val) {
            state.selectedUserLevels = val;
        },
        UPDATE_SELECTED_PREMISE_HAS_INVOICES(state, val) {
            state.selectedPremiseHasInvoices = val;
        },
        TOGGLE_OVERLAY(state, val) {
            state.shallShowOverlay =
                val !== undefined ? val : !state.shallShowOverlay;
        },
    },
    actions: {},
};