import mock from "@/@fake-db/mock";
import store from "@/store";

// let collections = store.state.app.selectedPremiseProductList;
// console.log(collections);
// let collectiondate = collections[0].SeedDate;
// let collectiondateSplit = collectiondate.split("T");
// console.log(collectiondateSplit[0]);

const date = new Date();
var firstDay = new Date("2022-01-01");
var nextWeek = new Date(firstDay.getTime() + 7 * 24 * 60 * 60 * 1000);
const nextDay = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);

// prettier-ignore
const nextMonth = date.getMonth() === 11 ? new Date(date.getFullYear() + 1, 0, 1) : new Date(date.getFullYear(), date.getMonth() + 1, 1)

// prettier-ignore
const prevMonth = date.getMonth() === 11 ? new Date(date.getFullYear() - 1, 0, 1) : new Date(date.getFullYear(), date.getMonth() - 1, 1)

const events = store.state.app.selectedPremiseCollections;
let collection_date = null;

// for (var i = 0; i < 52; i++) {
//     if (i == 0) {
//         collection_date = firstDay;
//     } else {
//         collection_date = new Date(
//             firstDay.getTime() + 7 * i * 24 * 60 * 60 * 1000
//         );
//     }
//     events[i] = {
//         id: 1,
//         url: "",
//         title: "1100L General Waste Bin",
//         start: collection_date,
//         end: collection_date,
//         allDay: true,
//         extendedProps: {
//             calendar: "General",
//         },
//     };
// }

const data = { events };

// ------------------------------------------------
// GET: Return calendar events
// ------------------------------------------------
mock.onGet("/apps/calendar/events").reply((config) => {
    // Get requested calendars as Array
    const calendars = config.params.calendars.split(",");

    return [
        200,
        data.events.filter((event) =>
            calendars.includes(event.extendedProps.calendar)
        ),
    ];
});

// ------------------------------------------------
// POST: Add new event
// ------------------------------------------------
mock.onPost("/apps/calendar/events").reply((config) => {
    // Get event from post data
    const { event } = JSON.parse(config.data);

    const { length } = data.events;
    let lastIndex = 0;
    if (length) {
        lastIndex = data.events[length - 1].id;
    }
    event.id = lastIndex + 1;

    data.events.push(event);

    return [201, { event }];
});

// ------------------------------------------------
// POST: Update Event
// ------------------------------------------------
mock.onPost(/\/apps\/calendar\/events\/\d+/).reply((config) => {
    const { event: eventData } = JSON.parse(config.data);

    // Convert Id to number
    eventData.id = Number(eventData.id);

    const event = data.events.find((e) => e.id === Number(eventData.id));
    Object.assign(event, eventData);

    return [200, { event }];
});

// ------------------------------------------------
// DELETE: Remove Event
// ------------------------------------------------
mock.onDelete(/\/apps\/calendar\/events\/\d+/).reply((config) => {
    // Get event id from URL
    let eventId = config.url.substring(config.url.lastIndexOf("/") + 1);

    // Convert Id to number
    eventId = Number(eventId);

    const eventIndex = data.events.findIndex((e) => e.id === eventId);
    data.events.splice(eventIndex, 1);
    return [200];
});