import mock from "@/@fake-db/mock";
/* eslint-disable global-require */
const data = {
  faqData: {
    // bins
    bins: {
      icon: "Trash2Icon",
      title: "Your Bin",
      qandA: [
        {
          question: "What can I put in my bin?",
          ans: "Please refere to our Binpedia page on our site for a full breakdown of what you can and can't put in your bin. Please remember that any contamination may be charged for, especially prohibited items such as electricals.",
        },
        {
          question: "What type of bags can I use in my bin?",
          ans: "If you have a general waste bin, you can use any bags.  If you have a recycling or glass bin, it’s best not to use any bags at all. We recommend tipping an internal recycling bin into your bin outside. This will save you money on bags and help the environment too. If you have a food bin, you can either put food directly into the bin without bags, or you can use clear plastic bags/liners. If you have ordered a bag only service from us, then you will have purchased a roll of bags up front. These are branded with the company who will be collected them and you can only use these bags for this service. If you need some more, let us know",
        },
        {
          question:
            "I'm having an event - can I get some additional bins or collections?",
          ans: "Yes that’s no problem. We can either arrange some additional bins to be delivered to you short term, or we can usually schedule some additional collections on your existing bins. Let us know what you need and we will let you know what we can do for you.",
        },
        {
          question: "I've had a missed collection - what do I do?",
          ans: "Please Let us know and we will find out what has gone wrong",
        },
        {
          question: "My bin has gone missing - what do I do?",
          ans: "Bin Loss Cover - We always recommend taking out our Bin Cover which covers you for loss or theft of your bin. Unfortunately the bin is your responsibility so you will need to buy another one from us, which can be costly. If you have Bin Cover, we will replace this free of charge. Please check the surrounding area in case it has been put back in the wrong place by mistake. Occasionally, the bin may fall into the back of the truck which is usually caused when the bin is overweight. Let us know if this has happened and our supplier will speak to the driver and replace the bin. If you suspect your bin has been stolen you can report this to the police and get a crime reference number, to claim on your business insurance. Unfortunately, you will need to pay us for a replacement bin.",
        },
        {
          question: "My bin is damaged - what do I do?",
          ans: "We expect normal wear and tear on the bin and can usually get it swapped for you if it is damaged. If the bin is damaged by the driver when a collection is being completed, please let us know so we can report it to the supplier immediately. Yes that’s no problem. Let us know or give us a call on 0330 390 7540 what you’d like to change to and from.",
        },
        {
          question: "What is the weight limit on my bin?",
          ans: "Check your duty of care certificate for the weight limit on your bin. We will always let you know when your bin has gone overweight so that you can amend your service, or your waste levels, to suit your needs.",
        },
        {
          question: "How can I stop getting excess weight charges?",
          ans: "Excess weight is charged when your bin is over the weight limit (check your Duty of Care certificate for your weight limit). We are committed to helping you reduce waste – can we help you consider what you are throwing away and therefore reduce how much you buy? If not, we need to change your service to be in line with your needs. Contact us and we will advise on the best solution for you.",
        },
      ],
    },
    // contact
    contact: {
      icon: "MailIcon",
      title: "Contact",
      qandA: [
        {
          question: "Is there a contact form?",
          ans: "Yes, we have 3 contact forms (General, Report an Issue, Request a Change) within the Portal, all of which are available from the left hand menu",
        },
        {
          question: "Can I email you?",
          ans: "Yes, please use csteam@betterwaste.co.uk and one of our team will be in touch to answer your query.",
        },
        {
          question: "Can I phone you?",
          ans: "Yes, you can give us a call Monday-Friday 9am-5.00pm on 0330 390 7540. This is a standard rate call.",
        },
        {
          question: "Can I change the way you contact me?",
          ans: "Yes that’s no problem. Let us know what you’d like to change.",
        },
      ],
    },
    // account
    account: {
      icon: "SettingsIcon",
      title: "Account",
      qandA: [
        {
          question: "How can I add on more services at a later date?",
          ans: "Great News. We can add new services to your account, or increase the services you already have. Just get in touch to let us know what you would like.",
        },
        {
          question:
            "My Direct Debit isn't what I was expecting - what do I do?",
          ans: "You should have received your bill 5 working days before the direct debit was taken, to your email address (check your spam filters if you haven’t received it).",
        },
        {
          question:
            "Do I have to pay by Direct Debit or can I send you cash/cheque?",
          ans: "We take your details for a Direct Debit when we set up your contract. If Direct Debit is not possible, we can accept BACs, CHAPS, or card payments, but prefer Direct Debit so that your contract continues without any disruption to service if you forget to pay. We do not accept cheques or cash unfortunately.",
        },
        {
          question: "How do I cancel my contract?",
          ans: "We will be sad to see you go. Please get in touch with your reason for wanting to cancel and we will let you know the next steps.",
        },
        {
          question: "Can I add someone to my account to speak on my behalf?",
          ans: "Yes that’s no problem. We will need the account holder to contact us and pass a few security questions and we can then add someone else onto the account to speak to us in the future.",
        },
        {
          question:
            "Can I change my email address, phone number or bank details?",
          ans: "Yes that’s no problem. Let us know or give us a call on 0330 390 7540 what you’d like to change to and from.",
        },
      ],
    },
  },
};

mock.onGet("/faq/data").reply((config) => {
  const { q = "" } = config.params;
  const queryLowered = q.toLowerCase();

  const filteredData = {};

  Object.entries(data.faqData).forEach((entry) => {
    const [categoryName, categoryObj] = entry;
    // eslint-disable-next-line arrow-body-style
    const filteredQAndAOfCategory = categoryObj.qandA.filter((qAndAObj) => {
      return qAndAObj.question.toLowerCase().includes(queryLowered);
    });
    if (filteredQAndAOfCategory.length) {
      filteredData[categoryName] = {
        ...categoryObj,
        qandA: filteredQAndAOfCategory,
      };
    }
  });

  return [200, filteredData];
});
